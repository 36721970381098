h1 {
  font-weight: 900;
  font-size: 1.4em;
}

h2 {
  font-weight: 750;
  font-size: 1.2em;
}

h3 {
  font-weight: 650;
  font-size: 1em;
}