html {
  position: fixed;
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  color: #474d66;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  font-size: 1rem;
  text-decoration: none;
  position: fixed;
  width: 100%;
  line-height: 1;
  overflow: hidden;
}
input[type='radio'] {
  margin-left: 0;
}
input {
  color: #474d66;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  font-size: 1rem;

}
p, pre {
  padding: 0;
  margin: 4px 0;
  line-height: normal;
}
pre {
  white-space: pre-wrap;
  /* to stop a token longer than a line making the whole layout horizontally
  scroll */
  word-break: break-all; 
}
h1 {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 1.0rem;
}

h2 {
  padding: 0;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-weight: normal;
}

textarea {
  font-size: inherit;
  font-family: inherit;
  color: #474d66;
  font-size: 14px;
  line-height: 1rem;
}
.border-black {
  border-color: #000000;
}
.border-white {
  border-color: #ffffff;
}
.normal {
  font-size: 0.8rem;
}
.small {
  font-size: 11px;
}
.micro {
  font-size: 9px;
}
.huge {
  font-size: 1rem;
}
.big {
  font-size: 0.9rem;
}
.text-dark {
  color: hsl(0, 0%, 20%);
  fill: hsl(0, 0%, 20%);
}
.text-light {
  color: hsl(0, 0%, 95%);
  fill: hsl(0, 0%, 95%);
}
.text-white {
  color: hsl(0, 0%, 100%);
  fill: hsl(0, 0%, 100%);
}
.text-gray {
  color: #666666;
  fill: #666666;
}
.text-gray-light1 {
  color: #aaa;
  fill: #aaa;
}
.text-gray-light2 {
  color: #eeeeee;
  fill: #eeeeee;
}
.text-yellow {
  color: #fcb400;
  fill: #fcb400;
}
.text-orange {
  color: #ff6f2c;
  fill: #ff6f2c;
}
.text-red {
  color: #f82b60;
  fill: #f82b60;
}
.text-pink {
  color: #ff08c2;
  fill: #ff08c2;
}
.text-purple {
  color: #8b46ff;
  fill: #8b46ff;
}
.text-blue {
  color: #2d7ff9;
  fill: #2d7ff9;
}
.text-blue-evergreen-ui {
  color: #3366FF;
  fill: #3366FF;
}
.text-dark-blue {
  color: #2455a0;
  fill: #2455a0;
}
.text-cyan {
  color: #18bfff;
  fill: #18bfff;
}
.text-teal {
  color: #20d9d2;
  fill: #20d9d2;
}
.text-green {
  color: #20c933;
  fill: #20c933;
}
.border-gray {
  border-color: #666666;
}
.border-evergreen-ui {
  border-color: #d8dae5;
}
.border-light-gray {
  border-color: #dddddd;
}
.border-gray-base {
  border-color: #666666;
}
.border-gray-light1 {
  border-color: #cccccc;
}
.border-gray-light2 {
  border-color: #eeeeee;
}
.border-yellow {
  border-color: #fcb400;
}
.border-orange {
  border-color: #ff6f2c;
}
.border-red {
  border-color: #f82b60;
}
.border-pink {
  border-color: #ff08c2;
}
.border-purple {
  border-color: #8b46ff;
}
.border-blue {
  border-color: #2d7ff9;
}
.border-cyan {
  border-color: #18bfff;
}
.border-teal {
  border-color: #20d9d2;
}
.border-green {
  border-color: #20c933;
}
.p0 {
  padding: 0;
}
.pt0 {
  padding-top: 0;
}
.pr0 {
  padding-right: 0;
}
.pb0 {
  padding-bottom: 0;
}
.pl0 {
  padding-left: 0;
}
.px0 {
  padding-left: 0;
  padding-right: 0;
}
.py0 {
  padding-top: 0;
  padding-bottom: 0;
}
.p-half {
  padding: 0.25rem;
}
.pt-half {
  padding-top: 0.25rem;
}
.pr-half {
  padding-right: 0.25rem;
}
.pb-half {
  padding-bottom: 0.25rem;
}
.pl-half {
  padding-left: 0.25rem;
}
.px-half {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.py-half {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.p1 {
  padding: 0.5rem;
}
.pt1 {
  padding-top: 0.5rem;
}
.pr1 {
  padding-right: 0.5rem;
}
.pb1 {
  padding-bottom: 0.5rem;
}
.pl1 {
  padding-left: 0.5rem;
}
.py1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.p2 {
  padding: 1rem;
}
.pt2 {
  padding-top: 1rem;
}
.pr2 {
  padding-right: 1rem;
}
.pb2 {
  padding-bottom: 1rem;
}
.pl2 {
  padding-left: 1rem;
}
.py2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px2 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.p3 {
  padding: 2rem;
}
.pt3 {
  padding-top: 2rem;
}
.pr3 {
  padding-right: 2rem;
}
.pb3 {
  padding-bottom: 2rem;
}
.pl3 {
  padding-left: 2rem;
}
.py3 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px3 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.p4 {
  padding: 4rem;
}
.pt4 {
  padding-top: 4rem;
}
.pr4 {
  padding-right: 4rem;
}
.pb4 {
  padding-bottom: 4rem;
}
.pl4 {
  padding-left: 4rem;
}
.py4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.px4 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.m0 {
  margin: 0;
}
.mt0 {
  margin-top: 0;
}
.mr0 {
  margin-right: 0;
}
.mb0 {
  margin-bottom: 0;
}
.ml0 {
  margin-left: 0;
}
.mx0 {
  margin-left: 0;
  margin-right: 0;
}
.my0 {
  margin-top: 0;
  margin-bottom: 0;
}
.m-half {
  margin: 0.25rem;
}
.mt-half {
  margin-top: 0.25rem;
}
.mr-half {
  margin-right: 0.25rem;
}
.mb-half {
  margin-bottom: 0.25rem;
}
.ml-half {
  margin-left: 0.25rem;
}
.mx-half {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.my-half {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.m1 {
  margin: 0.5rem;
}
.mt1 {
  margin-top: 0.5rem;
}
.mr1 {
  margin-right: 0.5rem;
}
.mb1 {
  margin-bottom: 0.5rem;
}
.ml1 {
  margin-left: 0.5rem;
}
.mx1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.m2 {
  margin: 1rem;
}
.mt2 {
  margin-top: 1rem;
}
.mr2 {
  margin-right: 1rem;
}
.mb2 {
  margin-bottom: 1rem;
}
.ml2 {
  margin-left: 1rem;
}
.mx2 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m3 {
  margin: 2rem;
}
.mt3 {
  margin-top: 2rem;
}
.mr3 {
  margin-right: 2rem;
}
.mb3 {
  margin-bottom: 2rem;
}
.ml3 {
  margin-left: 2rem;
}
.mx3 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.my3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.m4 {
  margin: 4rem;
}
.mt4 {
  margin-top: 4rem;
}
.mr4 {
  margin-right: 4rem;
}
.mb4 {
  margin-bottom: 4rem;
}
.ml4 {
  margin-left: 4rem;
}
.mx4 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.my4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.rounded {
  border-radius: 3px;
}
.dark {
  background-color: hsl(0, 0%, 20%);
}
.gray-light1 {
  background-color: #cccccc;
}
.gray-light2 {
  background-color: #eeeeee;
}
.gray {
  background-color: #666666;
}
.white {
  background-color: hsl(0, 0%, 100%);
}
.yellow {
  background-color: rgb(255, 217, 0, 1);
}
.yellow-light {
  background-color: rgb(247, 227, 115);
}
.orange {
  background-color: #ff6f2c;
}
.red {
  background-color: #f82b60;
}
.red-dark1 {
  background-color: #ba1e45;
}
.red-light1 {
  background-color: #ff9eb7;
}
.red-light2 {
  background-color: #ffdce5;
}
.pink {
  background-color: #ff08c2;
}
.purple {
  background-color: #8b46ff;
}
.blue {
  background-color: #2d7ff9;
}
.blue-light1 {
  background-color: #73b7fa;
}
.blue-light2 {
  background-color: #acd4fc;
}
.blue-light3 {
  background-color: #ddeeff;
}
.cyan {
  background-color: #18bfff;
}
.teal {
  background-color: #20d9d2;
}
.green {
  background-color: #20c933;
}
.green-dark1 {
  background-color: #338a17;
}
.green-light1 {
  background-color: #93e088;
}
.green-light2 {
  background-color: #d1f7c4;
}
.border {
  border-style: solid;
  border-width: 1px;
}
.border-thick {
  border-style: solid;
  border-width: 2px;
}
.border-thick1 {
  border-style: solid;
  border-width: 3px;
}
.border-thick2 {
  border-style: solid;
  border-width: 5px;
}
.border-top {
  border-top-style: solid;
  border-top-width: 1px;
}
.border-right {
  border-right-style: solid;
  border-right-width: 1px;
}
.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.border-left {
  border-left-style: solid;
  border-left-width: 1px;
}
.inline {
  display: inline;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.static {
  position: static;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-auto {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}
.justify-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-around {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.items-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
}
.items-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end;
}
.items-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}
.items-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  -ms-grid-row-align: baseline;
  align-items: baseline;
}
.items-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  -ms-grid-row-align: stretch;
  align-items: stretch;
}
.col-1 {
  width: 8.33333%;
}
.col-2 {
  width: 16.66667%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33333%;
}
.col-5 {
  width: 41.66667%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33333%;
}
.col-8 {
  width: 66.66667%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33333%;
}
.col-11 {
  width: 91.66667%;
}
.col-12 {
  width: 100%;
}
.width-full {
  width: 100%;
}
.height-full {
  height: 100%;
}
.row-6 {
  height: 50%;
}
.height-fill {
  min-height: 100vh;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.overflow-auto {
  overflow: auto;
}
.overflow-visible {
  overflow: visible;
}
.pointer {
  cursor: pointer;
}
.text {
  cursor: text;
}
.grab {
  cursor: grab;
}
.grabbing {
  cursor: grabbing;
}
.arrow {
  cursor: default;
}
.border-box {
  box-sizing: border-box;
}
.monospace {
  font-family: Menlo, monospace;
  font-size: 0.8rem;
}
.strong {
  color: hsl(0, 0%, 0%);
}
.stronger {
  font-weight: 600;
}
.strongest {
  font-weight: 700;
}
.regular {
  font-weight: normal;
}
.no-user-select {
  user-select: none;
  -webkit-user-select: none;
}
.center {
  text-align: center;
}
.strikethrough {
  text-decoration: line-through;
}

.green-highlight {
  background-color: rgba(167, 229, 197, 0.8);
  cursor: pointer;
}
.dark-green-highlight {
  background-color: rgba(130, 255, 170, 1);
  cursor: pointer;
}
.red-highlight {
  background-color: rgba(229, 50, 50, 0.8);
  cursor: pointer;
}
ol, ul {
  padding: 0;
  margin: 8px 0;
  padding-left: 16px;
}
li > p {
  padding: 0;
  margin: 4px;
}
.darken1-hover:hover {
    background-color: hsla(0, 0%, 0%, 0.05);
}

input:focus-visible, input:focus {
  border: none;
  outline: none
}